
@media screen and (min-width: 450px) {

    .refresh-diagnostics::after {
        content: ' Refresh';
    }

    .contact-us::after {
        content: ' Contact Us';
    }
}