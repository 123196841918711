@font-face {
    font-family: 'FontAwesome';
    src: url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.eot');
    src: url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
    url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.woff2') format('woff2'),
    url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.woff') format('woff'),
    url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.ttf') format('truetype'),
    url('//use.fontawesome.com/releases/v4.6.3/fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

html {
    height: 100%;
}

body {
    background-color: #f1f3f5;
    height: 100%;
}

.fas.fa-cogs.fa-spin {
    color: #929292;
}

.remove-user {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden;
}

#root {
    height: 100%;
}

.content-container {
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.05);
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    background-color: #f8f9fa;
    min-height: 100%;
}

nav {
    -webkit-box-shadow: 0 0.25rem 0.125rem 0 rgba(0,0,0,.05);
    box-shadow: 0 0.25rem 0.125rem 0 rgba(0,0,0,.05);
}

header {
    margin-left: -15px;
    margin-right: -15px;
}

.user-list .bg-secondary {
    color: white;
}

form .icon {
    margin-top: 11px;
    color: #929292;
}

form .checkbox label {
    margin-top: 6px;
}

.login {
    position: relative;
}

.login .icon a {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #929292;
}

.login-page .logo .image {
    width: 150px;
}

.login-page .logo .text {
    width: 150px;
}

.login-page .sign-in-btn {
    font-size: 1rem;
}

/** Custom tooltip appearing on the bottom */

.custom-tooltip-bottom {
    position: relative;
}

.custom-tooltip-bottom::before, .custom-tooltip-bottom::after {
    position: absolute;
    left: 50%;
    visibility: hidden;
}

.custom-tooltip-bottom::before {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #b4b4b4;
    margin-left: -8px;
    bottom: -8px;

}

.custom-tooltip-bottom::after {
    content: attr(data-tooltip);
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    bottom: -8px;
    font-size: 13px;
    width: 100px;
    margin-left: -50px;
    padding: 14px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    transform: translateY(100%);
    z-index: 1021;
}

.custom-tooltip-bottom:hover::before, .custom-tooltip-bottom:hover::after {
    visibility: visible;
}

/** Custom tooltip appearing on the top */
.custom-tooltip {
    position: relative;
}

.custom-tooltip::before, .custom-tooltip::after {
    position: absolute;
    left: 50%;
    visibility: hidden;
}

.custom-tooltip::before {
    content: "";
    border-width: 8px;
    border-style: solid;
    border-color: #b4b4b4 transparent transparent transparent;
    margin-left: -8px;
    top: -8px;
}

.custom-tooltip::after {
    content: attr(data-tooltip);
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    top: -8px;
    font-size: 13px;
    width: 290px;
    margin-left: -50px;
    padding: 14px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    transform: translateY(-100%);
    z-index: 1021;
}

.custom-tooltip:hover::before, .custom-tooltip:hover::after {
    visibility: visible;
}


.user-form-buttons, .settings-form-buttons {
    display: flex;
    margin: auto;
    width: 80%;
    justify-content: flex-end;
}

section.user-form-section > div:first-of-type {
    margin: auto;
    width: 80%;
    font-size: .95rem;
}

@media screen and (max-width: 992px) {
    .user-form-buttons {
        display: flex;
        margin: auto;
        width: 100%;
        justify-content: flex-end;
    }

    section.user-form-section > div:first-of-type {
        margin: auto;
        width: 100%;
    }
}

section.user-form-section > div > h4 {
    font-weight: 400;
    border-bottom: 1px solid #ccc;
}

.system-config-form .buttons .form-group {
    padding-right: 0px;
    padding-left: 30px;
}

.sftpgateway {
    color: #339ac3 !important;
}

.navbar-nav .nav-item {
    margin-right: 1em;
}

.navbar-brand .logo {
    width: 80px;
    margin-right: 0.5em;
}

.navbar-brand .text {
    height: 20px;
    margin-right: 0.6em;
}

@media (min-width: 992px) {
    .navbar-expand-lg .custom-navbar-toggler {
        display: none;
    }
}

.custom-navbar-toggler .nav-item {
    padding: 0 .4em;
}

.custom-navbar-toggler ul.navbar-nav {
    flex-direction: row;
}

.collapse-indicator {
    transform: rotate(-90deg);
    transition: transform 0.25s linear;
    padding: 10px 0;
}

.collapse-indicator.opened {
    transform: rotate(0deg);
    transition: transform 0.25s linear;
}

.cursor-pointer {
    cursor: pointer;
}

.collapse-enter {
    opacity: 0;
}

.collapse-enter.collapse-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.collapse-leave {
    opacity: 1;
}

.collapse-leave.collapse-leave-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
}

.form-group.required .col-form-label:after {
    content: " *";
    color: #ff7600;
}

.input-container {
    position: relative;
}

.input-container .input {
    width: 100%;
    padding-left: 36px !important;
}

.input-container .input-icon {
    position: absolute;
    left: 10px;
    top: 25%;
    color: #929292;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
    height: 30px;
}

/** Form placeholder **/
input::placeholder {
    color: #6c757d82 !important;
}


/** Custom Check Box **/
input[type=checkbox].checkbox, input[type=radio].radiobox {
    visibility: hidden;
    position: absolute;
    z-index: 20;
    width: 18px;
    height: 18px;
}

label input[type=checkbox].checkbox.checked+span:before {
    color: #007aff;
}

label input[type=checkbox].checkbox.checked+span:before {
    content: '\f00c';
}

label input[type=checkbox].checkbox+span:before {
    content: "\a0";
}

label input[type=checkbox].checkbox+span:before {
    font-family: "FontAwesome";
    font-size: 12px;
    border-radius: 0;
    content: "\a0";
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 1px;
    height: 17px;
    line-height: 14px;
    min-width: 17px;
    margin-right: 10px;
    border: 1px solid #bfbfbf;
    background-color: #f4f4f4;
    font-weight: 400;
    margin-top: -1px;
}

.checkbox-help-text {
    margin-top: 4px;
    font-size: 13px;
}

label:hover input[type=checkbox].checkbox+span:before {
    color: #2E7BCC;
    border-color: #2E7BCC;
}

.main-wrapper {
    min-height: 100%;
    padding-bottom: 75px;
    position: relative;
}

footer {
    bottom: 0;
    height: 50px;
    left: 0;
    position: absolute;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
}


footer > div {
    padding-top: 13px;
    flex: 1 0 33%;
}

footer > div:nth-child(2){
    text-align: center;
    flex: 1 1 33%;
}

footer > div:nth-child(3){
    text-align: right;
}


/** Navigation breadcrumb **/
.nav-breadcrumb {
    display: flex;
    padding: .75rem 1rem;
    background-color: inherit;
}

.nav-breadcrumb > a, .nav-breadcrumb > a:hover {
    font-size: 0.8rem;
    text-decoration: none;
}

/** Custom button **/
.sftpgateway-blue-btn {
    border-top-color: #329fbb;
    border-right-color: #329fbb;
    border-bottom-color: #329fbb;
    border-left-color: #329fbb;
    border-width: 1px;
    border-style: solid;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size:12px;
    padding: 5px 10px 5px 10px;
    text-decoration:none;
    display:inline-block;
    font-weight:bold;
    color: #FFFFFF;
    background-color: #54B8D1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#54B8D1), to(#1275AF));
    background-image: -webkit-linear-gradient(top, #54B8D1, #1275AF);
    background-image: -moz-linear-gradient(top, #54B8D1, #1275AF);
    background-image: -ms-linear-gradient(top, #54B8D1, #1275AF);
    background-image: -o-linear-gradient(top, #54B8D1, #1275AF);
    background-image: linear-gradient(to bottom, #54B8D1, #1275AF);
}

.sftpgateway-blue-btn:hover {
    cursor: pointer;
    border:1px solid #288197;
    background-color: #33a3bf;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#33a3bf), to(#0d5681));
    background-image: -webkit-linear-gradient(top, #33a3bf, #0d5681);
    background-image: -moz-linear-gradient(top, #33a3bf, #0d5681);
    background-image: -ms-linear-gradient(top, #33a3bf, #0d5681);
    background-image: -o-linear-gradient(top, #33a3bf, #0d5681);
    background-image: linear-gradient(to bottom, #33a3bf, #0d5681);
}

.sftpgateway-light-btn {
    border:1px solid #bbb6b6;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size:12px;
    padding: 5px 10px 5px 10px;
    text-decoration:none;
    display:inline-block;
    font-weight:bold;
    color: #000000;
    background-color: #D6D3D3;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D6D3D3), to(#949292));
    background-image: -webkit-linear-gradient(top, #D6D3D3, #949292);
    background-image: -moz-linear-gradient(top, #D6D3D3, #949292);
    background-image: -ms-linear-gradient(top, #D6D3D3, #949292);
    background-image: -o-linear-gradient(top, #D6D3D3, #949292);
    background-image: linear-gradient(to bottom, #D6D3D3, #949292);
}

.sftpgateway-light-btn:hover {
    cursor: pointer;
    border: 1px solid #a59e9e;
    background-color: #bdb9b9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#bdb9b9), to(#7b7878));
    background-image: -webkit-linear-gradient(top, #bdb9b9, #7b7878);
    background-image: -moz-linear-gradient(top, #bdb9b9, #7b7878);
    background-image: -ms-linear-gradient(top, #bdb9b9, #7b7878);
    background-image: -o-linear-gradient(top, #bdb9b9, #7b7878);
    background-image: linear-gradient(to bottom, #bdb9b9, #7b7878);
}

.sftpgateway-red-btn {
    border:1px solid #ea0024;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size:12px;
    padding: 5px 10px 5px 10px;
    text-decoration:none;
    display:inline-block;
    font-weight:bold;
    color: #FFFFFF;
    background-color: #FF2345;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FF2345), to(#871325));
    background-image: -webkit-linear-gradient(top, #FF2345, #871325);
    background-image: -moz-linear-gradient(top, #FF2345, #871325);
    background-image: -ms-linear-gradient(top, #FF2345, #871325);
    background-image: -o-linear-gradient(top, #FF2345, #871325);
    background-image: linear-gradient(to bottom, #FF2345, #871325);
}

.sftpgateway-red-btn:hover{
    cursor: pointer;
    border:1px solid #bc001d;
    background-color: #ef0025;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ef0025), to(#5a0d19));
    background-image: -webkit-linear-gradient(top, #ef0025, #5a0d19);
    background-image: -moz-linear-gradient(top, #ef0025, #5a0d19);
    background-image: -ms-linear-gradient(top, #ef0025, #5a0d19);
    background-image: -o-linear-gradient(top, #ef0025, #5a0d19);
    background-image: linear-gradient(to bottom, #ef0025, #5a0d19);
}

.sftpgateway-green-btn {
    border:1px solid #3a6d2b;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size:12px;
    padding: 5px 10px 5px 10px;
    text-decoration:none;
    display:inline-block;
    font-weight:bold;
    color: #FFFFFF;
    background-color: #4f953b;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4f953b), to(#3A5134));
    background-image: -webkit-linear-gradient(top, #4f953b, #3A5134);
    background-image: -moz-linear-gradient(top, #4f953b, #3A5134);
    background-image: -ms-linear-gradient(top, #4f953b, #3A5134);
    background-image: -o-linear-gradient(top, #4f953b, #3A5134);
    background-image: linear-gradient(to bottom, #4f953b, #3A5134);
}

.sftpgateway-green-btn:hover{
    cursor: pointer;
    border:1px solid #284c1e;
    background-color: #3c702d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3c702d), to(#243220));
    background-image: -webkit-linear-gradient(top, #3c702d, #243220);
    background-image: -moz-linear-gradient(top, #3c702d, #243220);
    background-image: -ms-linear-gradient(top, #3c702d, #243220);
    background-image: -o-linear-gradient(top, #3c702d, #243220);
    background-image: linear-gradient(to bottom, #3c702d, #243220);
}


/** Signout button **/
nav .fa-sign-out-alt:hover {
    font-size: 18px;
}

/** Twitter button **/
.fa-twitter:hover {
    font-size: 18px;
}

/* Password field */
.password-generator {
    position: relative;
}

.password-generator .icon {
    position: absolute;
    top: 0;
    right: 8px;
}

/* Azure Storage Key */
div.input {
    position: relative;
}

div.input .icon {
    position: absolute;
    top: 0;
    right: 24px;
}

div.default-folders .form-group {
    margin-bottom: 0rem;
}
